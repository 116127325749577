body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "dinBold";
  src: local("dinBold"), url("./fonts/din/DIN-Bold.ttf") format("truetype");
  font-weight: bold;
}

.notification-box {
  position: fixed;
  z-index: 2000;
  top: 10px;
  right: 10px;
  padding: 5px 15px;
  border-radius: 5px;
  animation: fade linear 5000ms;
}
@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.notification {
  padding: 0;
}
.notification .material-icons {
  color: #44bd32;
  font-size: 3rem;
  vertical-align: middle;
}
.res_spider_graph {
  margin-left: 0rem !important;
  overflow: auto;
}
.header-font-size {
  font-size: 1.25rem;
  font-weight: 400;
}

.center {
  text-align: center !important;
}
.bold {
  font-weight: 400;
}
/* header {
  height: 58px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  color: #212529;
} */
/* .res_update_uc {
  width: 24rem !important;
  margin-left: 0rem !important;
} */

.error-notification-box {
  position: fixed;
  z-index: 2000;
  top: 10px;
  right: 10px;
  padding: 5px 15px;
  border-radius: 5px;
  animation: fade linear 5000ms;
}
.error-notification {
  padding: 0;
  color: #fff;
}

.white-icon {
  filter: invert(100);
}

.graphChild-col-3 {
  /* background-color: #f1f1f1; */
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
  width: 80%;
  margin: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 230px;
  /* height: 268px; */
  font-size: 0.75rem;
  flex-wrap: wrap;
}


/* .MuiSlider-thumb {

  position: absolute !important;

  width: 48px !important;

  height: 48px !important;

  box-sizing: border-box !important;


  background-color: #ffffff00 !important;

  display: flex !important;

  -webkit-box-align: center;

  align-items: center ;

  -webkit-box-pack: center !important;

  justify-content: center ;


  top: 50% ;

  transform: translate(-50%, -50%) !important;

  left: 0% ;

  background-image: url("../public/favicon.ico") !important;

  background-repeat: no-repeat !important;

  background-size: 45px 45px !important ;

  border: none transparent !important;


  transition: none 0s ease 0s ;

}



.MuiSlider-thumb::before{

  box-shadow: none !important;

} */

@media screen and (max-width: 750px) {
   .MuiDataGrid-filterForm {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 14.563rem;
    background-color: white;
  }

.MuiDataGrid-filterFormDeleteIcon {
  align-self: flex-end;
}
}

#report-content {
  padding: 4px;
  /* width: 1000px; */
  /* height: 834px; */
}